@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
body{
  margin:0px;
  padding: 0px;
  font-family: 'Open Sans', sans-serif;
  
}
/* Barre de navigation */
/* nav{
  display:flex;
  flex-wrap:wrap;
  justify-content: space-between;
  padding: 20px;
  background-color: #fff;
  position: sticky;
  top: 0;
}
nav h1{
  font-size: 20px;
}
nav .onglets{
  display: flex;
  flex-wrap:wrap;
}
nav .onglets p{
  font-size: 17px;
  margin-right: 10px;
  cursor:pointer;
}
nav .onglets input{
  margin: 8px 20px;
  padding: 15px;
  border-radius: 30px;
  border:none;
  background-color: #f2f2f2;
  outline:none;
}
/* Fin de la barre de navigation */

/* Header */
/* header{
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
}
header h1{
  font-size: 50px;
  max-width: 500px;
  text-align:center;
}
header button{
  padding: 15px 20px;
  font-size: 20px;
  border:none;
  border-radius: 5px;
  outline:none;
  cursor:pointer;
}  */
/* Fin du header */

/* Section principale */

.main{
  margin: 20px;
  margin-top: 80px;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
}
/* Fin de toutes les cartes */

/* Video de présentation */
/* .main .video{
  margin-top: 124px;
  width: 90%;
  height: 100%;
}
.main .video img{
  width: 750px;
  height: 500px;
} */
/* Fin de la vidéo de présentation */
/* Fin de la section principale */

/* Pied de page */
/* Fin du pied de page */
.head{
  height: 400px;
}

@media screen and (max-width: 640px){
  nav .onglets .link, nav h1{
    display:none;
  }
  .cards .card img{
    width: 300px;
  }
}