.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token{
    color: black;
}
#tags:focus{
    box-shadow:none;
}
#tonyEstPlusFortQueMoi:checked,#spanTags > button{
    background-color: black;
    border-color: black;
}
#spanTags > ul{
    padding: 0;
    
}
#spanTags > ul > li{
    margin-left: 8px;
    margin-top: 6px;
}
#spanTags > ul > li:last-child{
    margin-left: 0;
    margin-top: 0px;
    padding: 0;
    
}

#spanTags > ul > li > input{
    padding: 16px 12px;
}

#spanTags > ul{
    width: inherit;
}
 
